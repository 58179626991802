.horizontal-container {
  overflow-x: hidden;
  width: 100%;
  padding: 10px 0;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.item {
  flex: 0 0 200px; /* Default width */
  height: 400px;
  transition: transform 0.3s ease, filter 0.3s ease, width 0.3s ease;
  filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background-color: black;
  margin: 0 10px;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
  border: solid 1px rgba(128, 128, 128, 0.343);
  cursor: pointer;
  border-radius: 5px;
  transition: 0.4s;
}
.item:hover::before {
  content: "Click Me";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  color: white;
  z-index: 13;
  filter: blur(0px);
}

.item.website {
  flex: 0 0 280px; /* Default width for website */
  width: 280px !important;
  height: 200px;
}

.item.active {
  transform: scale(1.15);
  flex: 0 0 230px;
  width: 230px !important;
  filter: none;
  box-shadow: 5px 10px 10px rgba(128, 128, 128, 0.846);
  margin: 0 30px;
  border-radius: 10px;
}

.item.active.website {
  flex: 0 0 400px;
  width: 400px !important;
  height: 230px;
  margin: 0 30px;
  border-radius: 5px;
}

.wrapper {
  display: flex;
  transition: transform 0.5s ease;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .item {
    flex: 0 0 150px;
    height: 250px;
  }

  .item.website {
    flex: 0 0 200px;
    width: 200px !important;
    height: 150px;
  }

  .item.active {
    flex: 0 0 180px;
    width: 180px !important;
  }

  .item.active.website {
    flex: 0 0 300px;
    width: 300px !important;
    height: 180px;
  }
}

@media (max-width: 480px) {
  .item {
    flex: 0 0 100px;
    height: 150px;
  }

  .item.website {
    flex: 0 0 150px;
    width: 150px !important;
    height: 100px;
  }

  .item.active {
    flex: 0 0 130px;
    width: 130px !important;
  }

  .item.active.website {
    flex: 0 0 220px;
    width: 220px !important;
    height: 130px;
  }
}
