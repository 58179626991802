.title h2 {
  margin: 0 !important;
  font-weight: 700;
  text-transform: capitalize;
}
.title h4 {
  margin: 0 !important;
  font-size: 14px;
  text-transform: uppercase;
}
