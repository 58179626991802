.intro {
  background: linear-gradient(to top, #000, #523b2e);
  color: white;
}
.intro .container {
  /* margin-top: 10vh; */
}
.intro .container .intro-content {
  height: 70vh;
}
.intro .container .intro-content h3 {
  font-size: 18px;
  font-weight: 700;
}
.intro .container .intro-content ul li span {
  font-weight: 600;
}
.intro .container .intro-content .action-btns .action-btn {
  cursor: pointer;
  text-decoration: none !important;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 14px;
  transition: 0.4s;
  background-color: #c1b9b6;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

.intro .container .intro-content .action-btns .action-btn:hover {
  box-shadow: 7px 10px 10px rgba(128, 128, 128, 0.625);
  transform: translateY(-10%);
}

.intro .container .intro-circle {
  background-color: aliceblue;
  position: absolute;
  background-image: url(../../../../assets/images/lumona-intro.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 400px;
  height: 400px;
}

@media (max-width: 992px) {
  .intro .container .intro-content {
    height: auto;
  }

  .intro .container .intro-content .action-btns .action-btn {
    margin-bottom: 2vh;
    width: 90%;
  }
  .intro .intro-bg {
    height: 500px;
  }
}
@media (max-width: 578px) {
  .intro .intro-bg {
    height: 350px;
  }
  .intro .container .intro-circle {
    width: 300px;
    height: 300px;
  }
}
