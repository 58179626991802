.header {
  background-color: #c1b9b5;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 8vh;
  z-index: 1999;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.141);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
.header .logo {
  width: 100px;
  margin-left: 4vw;
}
.header nav {
  display: flex;
  width: 80vw;
  justify-content: space-around;
  transition: all 0.3s ease;
  z-index: 1;
}

.navs {
  position: relative;
}

.header nav .nav-option {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: 0.4s;
  position: relative;
  text-decoration: none;
  color: black;
}

.header nav .nav-option.active {
  box-shadow: 2px 2px 5px #1c1c1cbe;
  background-color: #523b2e;
  font-weight: 600;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}
.header nav .nav-option:hover {
  box-shadow: 2px 2px 5px #1c1c1cbe;
}

.header nav .drop-down {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header nav .drop-down-option {
  width: 100%;
  background-color: #c1b9b6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  opacity: 0;
  transform: translateY(-15%) rotateX(90deg);
  cursor: pointer;
  text-decoration: none;
  visibility: hidden;
  display: none;
  color: black;
  transition: 0.4s;
  border: solid 1px rgba(105, 105, 105, 0.481);
}

.header nav .drop-down-option:nth-child(1) {
  transition: opacity 0.4s, transform 0.6s, visibility 0.4s;
}
.header nav .drop-down-option:nth-child(2) {
  transition: opacity 0.8s, transform 1s, visibility 0.8s;
}
.header nav .drop-down-option:nth-child(3) {
  transition: opacity 1.2s, transform 1.4s, visibility 1.2s;
}
.header nav .drop-down-option:nth-child(4) {
  transition: opacity 1.6s, transform 1.8s, visibility 1.6s;
}

.header .drop-down.hovered .drop-down-option {
  visibility: visible;
  display: block;
  opacity: 1;
  transform: translateY(0%) rotateX(0deg);
  transition: 0.4s;
}

.header .drop-down.hovered .drop-down-option:hover {
  background-color: #523b2e;
  border-radius: 10px;
  color: white;
}
.header nav .navs .drop-down-drop-down {
  position: absolute;
  top: 0%;
  right: calc(-51% - 200px);
  transform: translateX(-50%);
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header nav .navs .drop-down-drop-down-option {
  width: 100%; /* Ensure options take full width */
  background-color: #523b2e;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: white;
  opacity: 0;
  transform: translateY(-10%) rotateX(90deg);
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;
  visibility: hidden;
}
.header nav .navs .drop-down-drop-down-option:hover {
  background-color: #523b2e;
}
.header
  nav
  .nav-option.hovered
  .drop-down-option.hovered
  .drop-down-drop-down-option {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%) rotateX(0deg);
  transition: 0.4s;
}

/* Mobile Menu Icon */
.mobile-menu-icon {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
}

.mobile-menu-icon .icon-bar {
  width: 100%;
  height: 3px;
  background-color: #523b2e;
  transition: all 0.3s;
}

.mobile-menu-icon .icon-bar.open {
  transform: rotate(45deg);
  background-color: #523b2e;
}

/* Responsive Design */

/* For Mobile Devices (up to 767px) */
@media (max-width: 992px) {
  .header {
    padding: 0 10px;
    height: 10vh;
  }

  .header .logo {
    width: 100px;
  }

  .nav-menu {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 100%;
    right: 0;
    width: 60vw !important;
    background-color: #c1b9b6;
    height: auto;
    display: none;
    padding-left: 10px;
    visibility: hidden;
    transform: translateY(-100%);
    z-index: -1;
  }

  .nav-menu.open {
    display: flex;
    visibility: visible;
    transform: translateY(0%);
  }

  .nav-option {
    /* width: auto; Auto width based on content */
    padding: 10px 6px;
    border-bottom: 1px solid #ccc;
    text-align: center;
  }

  .navs:hover .drop-down {
    position: relative;
    transform: none;
    width: 100%;
    left: 10%;
  }

  .drop-down {
    position: relative;
    transform: none;
    left: 0;
    top: 0;
    width: 100%;
    transition: 0.4s;
  }

  .drop-down-option {
    padding: 10px;
  }

  .header nav .navs .drop-down-drop-down {
    z-index: 1888;
    right: 80px;
  }

  .mobile-menu-icon {
    display: flex;
  }
}

/* For Extra Small Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  .header .logo {
    width: 80px;
  }

  .nav-option {
    font-size: 12px;
    padding: 8px 6px;
  }
  .nav-option {
    width: 150px;
  }
  .header nav .navs .drop-down-drop-down {
    width: 150px;
  }
  .drop-down-option {
    font-size: 12px;
    padding: 8px;
  }
}
