.featured {
  padding-top: 10vh;
  /* margin-top: 10vh; */
  padding-bottom: 10vh;
  background: radial-gradient(circle at 100%, #523b2e, #c1b9b6);

  /* color: white; */
}
.featured .title {
  text-align: center;
}
.featured .featured-content {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  color: black;
  color: white;

  padding: 20px;
}
.featured .featured-content:nth-child(1) {
  color: black;
}
.featured .featured-content:nth-child(2) {
  color: #eee7e4;
}
.featured .featured-content:nth-child(3) {
  color: black;
}
.featured .featured-content:nth-child(4) {
  color: #eee7e4;
}
.featured .featured-project {
  transition: 0.4s;
  margin-top: 2vh;
  text-decoration: none;
}
.featured .featured-project:hover {
  cursor: pointer;
  /* background-color: #deb8878e;
  color: white; */
  box-shadow: 0px 0px 10px white;
  border-radius: 10px;
}
.featured .featured-project:hover .featured-content h2 {
  /* color: #3691cd; */
  text-decoration: underline;
}
.featured .featured-content span {
  font-weight: 600;
}
.featured .background-image-text {
  font-size: 12rem; /* Adjust the size as needed */
  font-weight: 800; /* Optional: make the text bold for better effect */
  color: transparent; /* Hide the default text color to reveal the background image */
  background-clip: text; /* Clip the background image to the text */
  -webkit-background-clip: text; /* For webkit browsers (Chrome, Safari) */
  background-size: cover; /* Ensure the image covers the text area */
  background-position: center; /* Center the image */
}
@media (max-width: 992px) {
  .featured {
    margin-top: 5vh;
  }
  .featured .featured-content {
    height: auto;
  }
}
