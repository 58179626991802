.portfolio {
  padding-top: 5vh;
  padding-bottom: 5vh;
  color: white;
  background: radial-gradient(ellipse at top, #523b2e, #c1b9b6);
}

.portfolio .portfolio-project h4 {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 2vh;
}

.portfolio .title {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.portfolio .portfolio-title {
  width: 70%;
  text-align: center;
}

@media (max-width: 768px) {
  .portfolio .portfolio-title {
    width: 90%;
  }

  .portfolio .portfolio-project h4 {
    font-size: 18px;
  }

  .portfolio {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 480px) {
  .portfolio .portfolio-title {
    width: 100%;
    padding: 0 10px;
  }

  .portfolio .portfolio-project h4 {
    font-size: 16px;
  }

  .portfolio {
    padding-left: 5px;
    padding-right: 5px;
  }
}
