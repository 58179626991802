.preloader {
  position: fixed; /* Keep the preloader fixed on the screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #c1b9b6, #523b2e);
  z-index: 9999; /* Ensure it appears above all other elements */
}

.spinner {
  width: 60px;
  height: 60px;
  border: 8px solid #523b2e; /* Light grey background */
  border-top: 8px solid #f3f3f3; /* Blue spinner color */
  border-radius: 50%; /* Make the spinner circular */
  animation: spin 1s linear infinite; /* Animation effect */
}

/* Keyframes for spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
