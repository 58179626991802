.base-services {
  margin-top: -5vh;
  /* background-color: black; */
  color: white;
  padding-top: 5vh;
  background: radial-gradient(ellipse at top, #523b2e, #c1b9b6);

  padding-bottom: 5vh;
}
.base-services .service-overview > div {
  width: 70%;
  margin-bottom: 2vh;
}
.base-services .service-categories {
}
.base-services .service-category {
  padding: 16px;
  border-radius: 10px;
  background-color: #2c323d;
  color: white;
  max-width: 38vw;
  margin: 20px;
  cursor: pointer;
  height: 50vh;
}

.base-services .service-sections {
  margin-bottom: 10px;
  cursor: pointer;
}
.base-services .service-sections .service-1 {
  height: 55vh;
  border-radius: 20px;
  background-color: darkcyan;
  background: radial-gradient(#523b2e, #000);

  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
  color: white;
}
.base-services .service-sections .service-1 .row {
  height: 100%;
}
.base-services .service-sections .service-1 .row > div {
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px;
}
.route-link {
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: black;
}
.route {
  transition: 0.4s !important;
}
.route:hover {
  box-shadow: 5px 7px 10px gray;
  background: radial-gradient(#523b2e, #000);
}
.route:hover .route-link {
  color: white;
}
.base-services .service-sections .service-2 {
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f3f3f3;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  margin: 10px;
  width: 31%;
}
.base-services .service-sections .service-3 {
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;
  width: 31%;
  background-color: #523b2e;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}
.base-services .service-sections .service-3 .route-link {
  color: white;
}
.base-services .service-sections .service-4 {
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #000000a2;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  margin: 10px;
  width: 31%;
}
.base-services .service-sections .service-4 .route-link {
  color: white;
}
.service-sections .more-info-action h5 {
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: underline #66cdaa;
}
.service-sections .more-info-action a {
  text-decoration: none;
  color: black;
  padding: 5px 20px;
  background-color: #f3f3f3;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.611);
  transition: 0.4s;
  text-transform: capitalize;
}
.service-sections .more-info-action a:hover {
  background-color: #ffff;
  transform: translateY(-10%);
  box-shadow: 5px 6px 10px black;
}
.service-sections .types {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-items: center;
  background-color: mediumaquamarine;
  background: radial-gradient(#f3f3f3, #c1b9b6);
}
.type-button {
  background-color: #523b2eac;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 1.4vh;
  border-radius: 10px;
  width: 60%;
  transition: 0.4s;
}
.type-button:hover {
  background-color: #523b2e;
  box-shadow: 2px 5px 10px gray;
}

.type-button.active {
  background-color: darkcyan;
  background: radial-gradient(#523b2e, #000);

  color: white;
  width: 80%;
}

@media (max-width: 992px) {
  .base-services .service-sections .service-1 {
    height: auto;
  }
  .service-sections .types {
    max-height: 20vh;
    background-color: red;
  }
  .base-services .service-sections .service-2,
  .base-services .service-sections .service-3,
  .base-services .service-sections .service-4 {
    width: 90%;
    height: 50px;
    margin: 0px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
}
