.lumona-hero {
  min-height: 92vh;
  background: linear-gradient(to right, #fff, #523b2e);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1vh;
}
.lumona-hero .opening-statements {
  width: 50vw;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  padding-left: 5vw;
}
.lumona-hero .opening-statements p a {
  color: #212529;
  font-weight: bold;
  font-style: italic;
}
.lumona-hero .opening-statements .socials {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.lumona-hero .opening-statements .socials a {
  color: #212529;
  text-decoration: none;
}
.lumona-hero .opening-statements .socials .social {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lumona-hero .opening-statements h2 {
  font-size: 46px;
}
.lumona-hero .lumona-image {
  width: 50vw;
  height: 92vh;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .lumona-hero {
    flex-direction: column;
    background: #523b2e;
    height: auto;
    min-height: auto;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
  .lumona-hero .opening-statements {
    width: 90vw;
    z-index: 13;
    color: #fff;
  }
  .lumona-hero .opening-statements p a {
    color: #fff;
  }
  .lumona-hero .opening-statements .socials a {
    color: #fff;
  }
  .lumona-hero .lumona-image {
    width: 100vw;
    height: 50vh;
    position: absolute;
    top: 10vh;
    right: 0;
    opacity: 0.2;
  }
}
