.services {
  margin-top: -5vh;
  padding-top: 5vh;
  background: radial-gradient(ellipse at top, #523b2e, #c1b9b6);
  color: white;
}
.services .title {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.services .service-overview > div {
  width: 60%;
}
.services .service-category {
  padding: 16px;
  border-radius: 10px;
  background-color: #2c323d;
  background: radial-gradient(#523b2e, #000);
  max-width: 35vw;
  margin: 20px;
  cursor: pointer;
  height: auto;
  transition: 0.4s;
  box-shadow: 0px 0px 5px gray;
}
.services .service-category .link-container {
  text-decoration: none;
  color: white;
}
.services .service-category:hover {
  background-color: black;
  box-shadow: 5px 10px 10px gray;
}
.services .service-category .tags > div {
  background-color: #c1b9b6;
  color: black;
  padding: 5px 10px;
  border-radius: 6px;
  margin: 5px;
  font-size: 12px;
}
.services .service-category-title {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.services .service-category-title h3 {
  font-size: 24px;
  font-weight: 600;
  width: 75%;
  text-transform: capitalize;
}
.services .service-category-bg-img {
  width: 100%;
  height: 30vh;
  background-color: gray;
  background-position: center;
  background-size: cover;
}

@media (max-width: 992px) {
  .services .service-overview > div {
    width: 90%;
  }
  .services .service-category {
    max-width: 90%;
    min-width: 90%;
    margin: 0px;
    margin-bottom: 20px;
  }
}
