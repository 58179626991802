.hero {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, #c1b9b6, #523b2e);
}
.hero .container {
  padding-top: 8vh;
}

.hero .hero-about {
  background: radial-gradient(ellipse at top, #523b2e, #000);
  height: 80vh;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
}
.hero .hero-about .about-me {
  color: white;
  text-decoration: none;
}
.hero .hero-about .hero-about-image-div {
  width: 250px;
  min-width: 250px;
  height: 250px;
  border-radius: 50%;
  border: solid 2px white;
  position: relative;
}
.hero-about-image-inner-div {
  background-color: #c1b9b6;
  border-radius: 50%;
  width: 200px;
  height: 200px;
}
.hero .hero-about .hero-about-image-div .hero-about-img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: contain;
  /* background-color: red; */
  position: absolute;
  top: 0;
  left: 0;
}
.hero .hero-about .am {
  font-weight: 300;
  margin: 0;
}
.hero .hero-about .first-name {
  font-weight: 600;
  font-size: 42px;
  margin: 0;
}
.hero .hero-about .second-name {
  font-weight: 600;
  font-size: 42px;
  margin: 0;
  margin-bottom: 3vh;
}

.hero .hero-content h2 {
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
}
.hero .hero-content h2 span {
  margin-left: 20px;
}
.hero .hero-portfolio {
  height: 52vh;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero .hero-portfolio::before {
  content: "";
  position: absolute;
  top: 0;
  left: 1.5%;
  right: 0;
  bottom: 0;
  /* background: #3e2f2863; */
  z-index: 12;
  border-radius: 20px;
}

.hero .hero-portfolio .video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 99%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  margin-left: 1.5%;
}

.hero .hero-portfolio .point-div {
  position: absolute; /* Make sure the icon is positioned over the video */
  top: 55%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}
.hero .hero-portfolio .point {
  color: white;
  width: 80px;
  height: 80px;
  padding: 16px;
  background: radial-gradient(
    rgba(0, 0, 0, 0.726),
    rgba(0, 0, 0, 0.077),
    transparent
  );
  backdrop-filter: blur(5px);
  box-shadow: 0px 0px 10px gray;
  border-radius: 50%;
  transition: 0.4s;
}
.hero .hero-portfolio:hover .point {
  box-shadow: 5px 10px 10px gray;
  transform: translateY(-10%);
  background-color: rgba(0, 0, 0, 0.536);
  border: solid 1px gray;
}

.hero .hero-yr {
  height: 26vh;
  background-color: #523b2e;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  color: white;
  /* width: 200px; */
}
.hero .hero-yr h3 {
  font-weight: 700;
  font-size: 52px;
}

.hero .hero-project {
  height: 26vh;
  background-color: #c1b9b6;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.hero .hero-project h3 {
  font-weight: 700;
  font-size: 52px;
}

.hero .hero-download .hero-download-btn {
  background-color: black;
  background: radial-gradient(#523b2e, #c1b9b6ea);
  text-transform: uppercase;
  font-size: 14px;
  color: white;
  height: 18vh;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  transition: 1s;
  text-decoration: none;
}
.hero .hero-download-btn.hovered {
  background-color: white;
  box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.274);
}
.hero .hero-download .hero-download-btn .hero-download-inner {
  transition: 0.4s;
  width: 100px;
  height: 100px;
  background-color: white;

  clip-path: polygon(0 60%, 25% 60%, 25% 0, 75% 0, 76% 60%, 100% 60%, 50% 100%);
  box-shadow: 0px 0px 10px gray;
  transform: rotateZ(-90deg);
}

.hero .hero-services {
  height: 18vh;
  border-radius: 20px;
  position: relative;
  /* width: 300px; */
}
.hero .hero-services .hero-services-image {
  background-image: url(../../../../assets/images/hero-service.jpg);
  background-position: center;
  background-size: cover;
  height: 100%;
  min-width: 18vw;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
}
.hero .hero-services .hero-services-btn {
  background: radial-gradient(#000, #523b2e);
  text-align: center;
  height: 18vh;
  width: 20vw;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0);
  transition: 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
}

.hero .hero-services .hero-services-btn:hover {
  background: radial-gradient(#523b2e, #000);
}
.hero .hero-services .hero-services-btn.move {
  transform: translateX(94%); /* Moves the button to the right */
  box-shadow: -5px 5px 10px rgba(224, 224, 224, 0.463);
  border-radius: 20px;
}

@media (max-width: 992px) {
  .hero {
    height: auto;
  }
  .hero .container {
    padding-top: 2vh;
  }
  .hero .hero-content {
    margin-top: 2vh;
  }
  .hero .hero-about {
    background-color: #8184d2;
    height: 75.5vh;
  }
  .hero .hero-content h2 {
    line-height: 1;
  }
  .hero .hero-portfolio {
    height: 30vh;
  }
  .hero .hero-portfolio .video-bg {
    width: 100%;
  }
  .hero .hero-stats {
    display: flex;
    margin-top: 2vh;
    height: 20vh;
    justify-content: space-between;
  }
  .hero .hero-stats .hero-yr {
    height: 18vh;
    border-radius: 20px;
  }
  .hero .hero-stats .hero-project {
    height: 18vh;
    border-radius: 20px;
  }
  .hero .hero-download {
    margin-bottom: 2vh;
    text-align: center;
    width: 40%;
  }
  .hero .hero-download .hero-download-btn {
    height: 10vh;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero .hero-services {
    width: 59%;
  }
  .hero .hero-services .hero-services-image {
    height: 10vh;
    width: 100%;
    min-width: 100%;
    border-radius: 20px;
    position: relative;
  }
  .hero .hero-services .hero-services-btn {
    background-color: rgba(0, 0, 255, 0.548);
    height: 10vh;
    width: 100%;
  }
  .hero .hero-services .hero-services-btn.move {
    transform: translateX(6%); /* Moves the button to the right */
    min-width: 90%;
  }
  .hero .hero-download .hero-download-btn .hero-download-inner {
    transition: 0.4s;
    width: 60px;
    height: 60px;
  }
}
