.overview {
  /* margin-top: 10vh; */
  text-align: center;
  padding-top: 10vh;
  /* padding-bottom: 5vh; */
  background: linear-gradient(to bottom, #000, #c1b9b6);
  color: white;
}
.overview .title {
  margin-bottom: 5vh;
}
.overview-service {
  height: 50vh;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
  color: white;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  width: 24.5vw;
  position: relative;
  text-decoration: none;
  border-radius: 10px;
}
.overview-service::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.8s;
  border-radius: 10px;
  background: linear-gradient(to bottom, transparent, black);
}
.overview-service:hover::before {
  background: radial-gradient(#523b2ef1, #000000f1);
}
.overview-service .overview-content {
  position: relative;
  z-index: 12;
  transition: 0.4s;
}
.overview-service .overview-content h2 {
  font-size: 26px;
  transform: translateY(-20%);
}
.overview-service:hover .overview-content {
  transform: translateY(-50%);
}
@media (max-width: 992px) {
  .overview-service {
    width: 90%;
    margin-top: 2vh;
    height: 40vh;
  }
}
