/* FOOTER */
footer {
  position: absolute;
  width: 100vw;
  height: 30vh;
  background-color: #c1b9b5;
  background: radial-gradient(ellipse at bottom, #523b2edd, #c1b9b5);
  padding-left: 10%;
  padding-right: 10%;
}
footer .socials a {
  margin-right: 10px;
}

footer > .container > .logo > img {
  width: 191px;
}
footer li {
  list-style: none;
}
.footer_footer {
  border-top: solid #aaa 1px;
  position: absolute;
  bottom: 0;
  width: 80%;
  text-align: center;
  color: #d3d3d3;
}

@media (max-width: 952px) {
  footer {
    height: auto;
    min-height: 45vh;
  }
  footer .quick_links {
    display: none;
  }

  footer .socials {
    display: none;
  }
}
